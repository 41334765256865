<template>
  <div>
    <v-card
      outlined
      v-for="(v, index) in progressionData"
      :key="index"
      class="mb-3"
      :style="{ borderColor: v.color ? v.color : 'rgba(0, 0, 0, 0.12)' }"
    >
      <v-card-title class="text-body-1 align-center mb-1">
        <v-chip
          class="table-chips align-self-right text-right"
          :color="v.color ? v.color : 'grey lighten-1'"
          dark
        >
          <span class="text-caption">{{ v.text ? v.text : v.state }}</span>
        </v-chip>
      </v-card-title>
      <v-card-text>
        <div class="font-weight-bold text-body-1 mb-2">
          {{
            $t("tickets.fields.status.createBy", {
              author: `${v.author}:${v.fullname ? v.fullname : ''}`,
              date: $moment(v.created).format("YYYY-MM-DD HH:mm:ss"),
            })
          }}
        </div>
        <div class="text-justify">{{ v.comment }}</div>
      </v-card-text>
    </v-card>
    <v-card outlined>
      <v-card-title>
        <div class="font-weight-bold text-subtitle-2 mb-4">
          <span class="secondary-font font-weight-bold">{{ progression.title }}</span>
        </div>
      </v-card-title>
      <v-card-text>
        <ticket-progress-form :progression-statuses="progression.states" ref="progression" :is-valid.sync="isValid"></ticket-progress-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="save()"
          :loading="loading.save"
          :disabled="!isValid"
          text
          >{{ $t("btn.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import TicketProgressForm from './ticket-progress-form.vue';
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import { mapActions } from "vuex";

export default {
  mixins: [FormDelayTouchMixin],
  props: {
    progressionData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    progression: {
      type: Object,
      default: function () {
        return {};
      },
    },
    formsId: {
      type: Number,
    },
    ticketId: {
      type: String,
    },
  },
  data: () => ({
    loading: {
      save: false,
    },
    isValid: false
  }),
  methods: {
    ...mapActions({ notify: "notification/notify", request: "request" }),
    async save() {
      this.notify({ status: false });
      if (!this.$refs.progression.validate()) {
        this.notify({ message: this.$t("form_error") });
        return;
      }

      this.loading.save = true;
      const progressionData = this.$refs.progression.getProgressionData();
      try {
        const response = await this.request({
          url: "tickets.addStatus",
          method: "POST",
          data: {
            state: progressionData.status,
            comment: progressionData.comment,
            formsId: this.formsId,
            ticketId: this.ticketId,
          },
          messages: {
            200: this.$t("tickets.addStatus.success"),
            403: true,
            400: true,
            404: this.$t("tickets.errors.404"),
            422: (error) => {
              if (error.code === "E101") {
                return this.$t("form_error");
              } else {
                return this.$t("error_occured");
              }
            },
            500: true,
          },
        });
        const data = response.data;
        // update progress
        this.$emit("updateProgress", data);
        // reset form
        this.resetForm();
      } catch (error) {
        // ermpty
      }
      this.loading.save = false;
    },
    resetForm() {
      this.$refs.progression.resetValidation();
      this.$refs.progression.reset();
    },
  },
  watch: {
    ticketId(val) {
      if (val) {
        this.resetForm();
      }
    },
  },
  components: {
    TicketProgressForm
  }
};
</script>