<template>
  <v-dialog v-model="dialogValue" max-width="500">
    <v-card>
      <v-card-title>
        <span class="secondary-font">{{ $t("tickets.filter.title") }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-3">
        <forms-filter
          :sla-items="slaItems"
          :schema="schema"
          ref="filter"
          :statuses="statuses"
          :channels="channels"
        >
        </forms-filter>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="resetFilter()" text color="danger">{{
          $t("btn.reset")
        }}</v-btn>
        <v-btn @click="applyFilter()" text color="primary">{{
          $t("btn.filter")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import FormsFilter from "./../forms/filter/index";
export default {
  props: {
    dialog: Boolean,
    schema: {
      type: Array,
      default: function () {
        return [];
      },
    },
    channels: {
      type: Array,
      default: function () {
        return [];
      },
    },
    statuses: {
      type: Array,
      default: function () {
        return [];
      },
    },
    slaItems: Array,
  },
  data: () => ({}),
  methods: {
    ...mapActions({ setFilters: "ticket/setFilters" }),
    applyFilter() {
      const filters = this.$refs.filter.getFilters();
      this.setFilters(filters);

      this.$emit("apply-filter");
      this.dialogValue = false;
    },
    resetFilter() {
      const filters = this.$refs.filter.resetFilters();

      this.setFilters(filters);

      this.$emit("apply-filter");
      this.dialogValue = false;
    },
  },
  computed: {
    dialogValue: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        this.$emit("update:dialog", value);
      },
    },
    filtersValue: {
      get: function () {
        return this.filters;
      },
      set: function (value) {
        this.$set("update:filters", value);
      },
    },
  },
  components: {
    FormsFilter,
  },
};
</script>