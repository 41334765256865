<template>
  <component
    :is="`${options.type}-field`"
    :options="options"
    :value.sync="modelValue"
    v-if="isAllowedType(options.type)"
  >
  </component>
</template>

<script>
import TextField from "./fields/text";
import SelectField from "./fields/select";
import DatetimeField from "./fields/datetime";
import ModelField from "./fields/model";

export default {
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    value: {
      type: [String, Object, Array],
    },
  },
  data: () => ({
    allowedFieldType: ['text', 'select', 'datetime', 'model'],
  }),
  methods: {
    isAllowedType(type) {
      return this.allowedFieldType.includes(type);
    }
  },
  computed: {
    modelValue: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        return this.$emit('update:value', value);
      }
    }
  },
  components: {
    TextField,
    SelectField,
    DatetimeField,
    ModelField
  }
}
</script>