<template>
  <div>
    <v-form ref="form" v-model="isValidValue">
      <v-select
        :placeholder="$t('tickets.fields.status.placeholder')"
        :label="$t('tickets.fields.status.title')"
        v-model="form.status"
        class="font-weight-bold"
        :items="progressionStatuses"
        :rules="rulesStatus"
        dense
      ></v-select>
      <v-textarea
        class="font-weight-bold mt-2"
        :label="$t('tickets.fields.comment.title')"
        :placeholder="$t('tickets.fields.comment.placeholder')"
        v-model="form.comment"
        :rows="3"
        no-resize
        :rules="rulesComment"
        dense
      ></v-textarea>
    </v-form>
  </div>
</template>

<script>
import { maxLenghtTicketComment } from "./../../constants";
export default {
  props: {
    isValid: Boolean,
    progressionStatuses: Array,
  },
  data: () => ({
    form: {
      state: null,
      commnent: null,
    },
  }),
  methods: {
    getProgressionData() {
      return this.form;
    },
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  computed: {
    isValidValue: {
      get: function () {
        return this.isValid;
      },
      set: function (value) {
        return this.$emit("update:isValid", value);
      },
    },
    rulesStatus() {
      return [
        (value) => {
          if (value == null || value === "")
            return this.$t("tickets.fields.status.required");
          return true;
        },
      ];
    },
    rulesComment() {
      return [
        (value) => {
          if (value == null || value === "")
            return this.$t("tickets.fields.comment.required");
          if (value.length > maxLenghtTicketComment)
            return this.$t("tickets.fields.comment.maxLength", {
              max: maxLenghtTicketComment,
            });
          return true;
        },
      ];
    },
  },
};
</script>