<template>
  <div>
    <v-card
      class="rounded-lg"
      elevation="1"
      v-show="options.isVisible && isVisible"
      :class="{ 'field-error': hasError }"
    >
      <v-card-text class="px-5">
        <slot name="field-info"></slot>
        <v-autocomplete
          dense
          :placeholder="$t('forms.builder.fields.model.placeholder')"
          class="mb-4"
          :rules="rulesField"
          :items="items"
          :search-input.sync="search"
          :loading="loading"
          hide-no-data
          :clearable="!options.isRequired"
          v-model="modelValue"
          return-object
          ref="field"
          @focus="onFocus"
          :readonly="isReadonly"
        ></v-autocomplete>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FieldDisplayMixin from "./../mixins/field-display";
export default {
  mixins: [FieldDisplayMixin],
  data: () => ({
    items: [],
    search: null,
    loading: false,
    lastQuery: null,
  }),
  created() {
    this.initModelData();
  },
  watch: {
    search(val) {
      let value = val;
      if ((value == null || value === "")) return;

      if(value === this.modelValue?.text) {
        return;
      }

      if(this.update) {
        value = null;
      }
      
      // cancel old query
      if (this.lastQuery) clearTimeout(this.lastQuery);

      this.lastQuery = setTimeout(() => {
        this.query(value);
      }, 500);
    },
    identifier(val) {
      if (!val) {
        this.modelValue = null;
      }
      if (this.modelValue) {
        this.items.push({ ...this.modelValue });
      }
    },
    update(val) {
      if (val) return;
      if (!this.hasParents) {
        this.query();
      }
    },
  },
  methods: {
    ...mapActions({ request: "request" }),
    initModelData() {
      if (this.hasParents) {
        for (const parent of this.options.parents) {
          this.$watch(`formData.${parent.field}`, () => {
            if(!this.update) {
              this.modelValue = null;
            }
            this.items = [];
            this.query();
          });
        }
        return;
      }
      this.query();
    },
    async query(v) {
      this.loading = true;
      const params = { identifier: this.options.hash };
      const items = [];

      if (v) {
        items.push({ label: v });
      }
      if (this.hasParents) {
        for (const parent of this.options.parents) {
          const item = {};
          let value = null;
          if (this.formData[parent.field]) {
            if (typeof this.formData[parent.field] === "object") {
              if (this.formData[parent.field]?.value) {
                value = this.formData[parent.field]?.value;
              } else if (this.formData[parent.field]?.length) {
                value = this.formData[parent.field][0]?.value;
              }
            } else {
              value = this.formData[parent.field];
            }
          }
          item[parent.hash] = value;
          items.push(item);
        }
      }
      try {
        const response = await this.request({
          url: "models-fields.get",
          params: { ...params, item: JSON.stringify(items) },
        });
        this.items = this.$utils
          .getPropertyValue(response.data, "data.items", [])
          .map((v) => {
            return {
              text: v.item.label,
              value: v.item.value,
            };
          });
      } catch (error) {
        // empty
      }
      this.loading = false;
    },
    onChange() {
      return;
    },
  },
  computed: {
    rulesField() {
      const rules = [];
      if (!this.options.isVisible || !this.isVisible) return rules;

      if (this.options.isRequired) {
        rules.push((value) => {
          if (value == null || value === "")
            return this.$t("common.fields.value.required");
          return true;
        });
      }
      return rules;
    },
    hasParents() {
      return this.options.parents?.length ? true : false
    }
  },
};
</script>