export default {
  computed: {
    modelValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        return this.$emit("update:value", value);
      },
    },
  }
}