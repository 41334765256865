<template>
  <div>
    <v-select
      v-model="modelValue"
      :items="options.items"
      dense
      clearable
      :label="options.title"
    ></v-select>
  </div>
</template>

<script>
import FieldFilterMixin from './../mixins/field-filter';
export default {
  mixins: [FieldFilterMixin],
  props: {
    options: Object,
    value: [String, Object, Array],
  },
  data: () => ({}),
  computed: {
    modelValue: {
      get: function () {
        return this.value.value;
      },
      set: function (value) {
        return this.$emit("update:value", { value: value });
      },
    },
  }
};
</script>