<template>
  <v-card>
    <v-card-text class="px-5">
      <div class="font-weight-bold text-subtitle-1 mt-2">
        {{ $t("tickets.fields.sla.title") }}
      </div>
      <div class="mb-5 text-justify">
        {{ $t("tickets.fields.sla.description") }}
      </div>
      <v-select
        :clearable="!readonly"
        dense
        :items="slaItems"
        :item-text="(item) => item.title"
        v-model="form.sla"
        :label="$t('tickets.fields.sla.title')"
        :readonly="readonly"
      ></v-select>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    sla: Object,
    slaData: Object,
    readonly: Boolean
  },
  data: () => ({
    form: {
      sla: null,
    },
  }),
  methods: {
    getSlaData() {
      return this.form;
    }
  },
  watch: {
    slaData: {
      handler(val) {
         this.form.sla = val?.value ? val.value : null
      },
      deep: true
    }
  },
  computed: {
    slaItems() {
      if(this.slaData && this.readonly) {
        return [{ title: this.slaData.title, value: this.slaData.value }];
      }else{
        return this.$utils.getPropertyValue(this.sla, 'targets', [])
      }
    }
  }
};
</script>