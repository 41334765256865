<template>
  <div>
    <v-card class="rounded-lg" elevation="1" v-show="options.isVisible && isVisible">
      <v-card-text class="px-5">
        <slot name="field-info"></slot>
        <v-text-field
          dense
          :placeholder="$t('forms.builder.fields.datetime.placeholder')"
          class="mb-4"
          v-model="modelValue"
          :rules="rulesField"
          :clearable="!options.isRequired"
          ref="field"
          @click="selectDatime()"
          readonly
        ></v-text-field>
      </v-card-text>
    </v-card>
    <datetime-picker :format="options.format" :dialog.sync="dialog" @apply="apply"></datetime-picker>
  </div>
</template>

<script>
import FieldDisplayMixin from "../mixins/field-display";
import DatetimePicker from './../../../commons/datetime-picker.vue'
export default {
  mixins: [FieldDisplayMixin],
  data: () => ({
    dialog: false
  }),
  methods: {
    selectDatime() {
      if(this.isReadonly) return;
      this.dialog = true;
    },
    apply(val) {
      this.modelValue = val;
    }
  },
  computed: {
    rulesField() {
      const rules = [];
      if (!this.options.isVisible || !this.isVisible) return rules;

      if (this.options.isRequired) {
        rules.push((value) => {
          if (value == null || value === "")
            return this.$t("common.fields.value.required");
          return true;
        });
      }
      return rules;
    },
  },
  components: {
    DatetimePicker
  }
};
</script>