<template>
  <div>
    <v-form ref="form" class="forms">
      <v-text-field
        :label="$t('tickets.fields.identifier.title')"
        v-model="filters.identifier"
        clearable
        dense
        class="mb-2"
      ></v-text-field>
      <v-text-field
        :label="$t('tickets.fields.created.title')"
        v-model="daterange"
        @click="datepicker = true"
        readonly
        clearable
        class="mb-2"
        dense
      ></v-text-field>
      <v-select
        :items="slaItems"
        :label="$t('tickets.fields.sla.title')"
        v-model="filters.sla.value"
        dense
        class="mb-2"
        clearable
      ></v-select>
      <v-text-field
        :label="$t('tickets.fields.author.title')"
        v-model="filters.author"
        clearable
        dense
        class="mb-2"
      ></v-text-field>
      <v-text-field
        :label="$t('tickets.fields.contactId.title')"
        v-model="filters.contact"
        clearable
        dense
        class="mb-2"
      ></v-text-field>
      <v-select
        :items="channels"
        :label="$t('tickets.fields.channel.title')"
        v-model="filters.channel.type"
        autocomplete="off"
        clearable
        dense
        class="mb-2"
      ></v-select>
      <v-select
        :label="$t('tickets.fields.status.title')"
        v-model="filters.progress.state"
        :items="statuses"
        clearable
        dense
        class="mb-2"
      ></v-select>
      <div v-for="(field, index) in schema" :key="index">
        <field
          :options="field"
          :value.sync="filters.ticket[field.name]"
          class="mb-2"
        ></field>
      </div>
    </v-form>
    <v-dialog
      ref="dialog"
      v-model="datepicker"
      :return-value.sync="date"
      persistent
      width="290px"
    >
      <v-date-picker range v-model="date" scrollable>
        <v-spacer></v-spacer>
        <v-btn text @click="clearDaterange()">{{
          $t("btn.clear")
        }}</v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(date)">
          <span class="font-weight-bold">{{ $t("btn.ok") }}</span>
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from "vue";
import { defaultFilterForms } from "./../../../constants";
import Field from "./field.vue";

export default {
  props: {
    schema: {
      type: Array,
      default: function () {
        return [];
      },
    },
    statuses: Array,
    channels: Array,
    slaItems: Array,
  },
  created() {
    this.initFiltersForms();
    this.initFilterData();
  },
  data: () => ({
    filters: Vue._.cloneDeep(defaultFilterForms),
    datepicker: false,
    date: null,
    daterange: null
  }),
  methods: {
    initFiltersForms() {
      this.filters.ticket = {};
      for (const field of this.schema) {
        let value = null;
        if (["select", "model"].includes(field.type)) {
          value = { value: null }
        }

        this.$set(this.filters.ticket, field.name, value);
      }
    },
    initFilterData() {
      if(this.filtersX.identifier) {
        this.filters.identifier = this.filtersX.identifier;
      }
    },
    getFilters() {
      return this.filters;
    },
    resetFilters() {
      this.filters = this._.cloneDeep(defaultFilterForms);
      this.initFiltersForms();
      return this.filters;
    },
    clearDaterange() {
      this.date = null;
      this.datepicker = false;
    }
  },
  watch: {
    schema: {
      handler() {
        this.initFiltersForms();
      },
      deep: true,
    },
    date(val) {
      const range = this.$utils.parseRangeDates(val);
      if (!range) {
        this.filters.created.start = null;
        this.filters.created.end = null;
        this.daterange = null;
      } else {
        this.daterange = `${range[0]}-${range[1]}`;
        this.filters.created.start = range[0];
        this.filters.created.end = range[1];
      }
    },
  },
  computed: {
    ...mapGetters({ filtersX: "ticket/filters" }),
  },
  components: {
    Field,
  },
};
</script>