<template>
  <div>
    <div v-if="socketIsAuthenticated">
      <tickets ref="tickets" :query="query"></tickets>
      <footer-bar class="justify-center"></footer-bar>
    </div>
    <div
      class="d-flex justify-center align-center"
      style="height: 300px"
      v-else
    >
      <v-progress-circular
        indeterminate
        color="secondary"
        width="4"
        size="60"
      ></v-progress-circular>
    </div>
  </div>
</template>


<script>
import FooterBar from "./../../components/layout/footer-bar";
import Tickets from "./../../components/tickets/index";
import { mapGetters } from "vuex";
export default {
  mounted() {
    // connect to socket
    if (!this.socketIsConnected) {
      this.$socket.open();
    }
  },
  data: () => ({
    query: {
      to: {},
      from: {},
    }
  }),
  async beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.query.to = to.query;
      vm.query.from = from.query;
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.query.to = to.query;
    this.query.from = from.query;
    next();
  },
  metaInfo() {
    return {
      title: this.$i18n.t("tickets.title"),
    };
  },
  computed: {
     ...mapGetters({
      socketIsConnected: "socketIsConnected",
      socketIsAuthenticated: "socketIsAuthenticated",
    }),
  },
  components: {
    Tickets,
    FooterBar,
  },
};
</script>