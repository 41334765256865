<template>
  <div>
    <v-card class="py-0 mb-4 rounded-lg">
      <v-card-title class="py-0 px-2">
        <v-list two-line class="py-0">
          <v-list-item class="my-0 px-2">
            <v-list-item-avatar
              class="my-0 mr-2 ml-0"
              @click="drawer.left = 1"
              v-if="!$vuetify.breakpoint.smAndUp"
            >
              <v-icon>mdi-menu</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="py-0">
              <v-list-item-title>
                <span class="text-h6 font-weight-medium">
                  {{ formsName ? formsName : $i18n.t("tickets.help.title") }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span>
                  {{
                    serviceName
                      ? $i18n.t("tickets.help.subtitle2", {
                          name: serviceName,
                        })
                      : $i18n.t("tickets.help.subtitle")
                  }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-spacer></v-spacer>
        <template
          v-if="
            isTicketSelected &&
            $permission.$can('ticket.create', ['user', 'service'])
          "
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="newTicket()"
                v-bind="attrs"
                v-on="on"
                small
                elevation="0"
                :fab="$vuetify.breakpoint.smAndDown"
                color="primary"
              >
                <v-icon :small="$vuetify.breakpoint.mdAndUp"
                  >mdi-ticket-outline</v-icon
                >
                <span class="ml-1" v-if="$vuetify.breakpoint.mdAndUp">{{
                  $t("btn.new")
                }}</span>
              </v-btn>
            </template>
            <span>{{ $t("tickets.btn.add") }}</span>
          </v-tooltip>
        </template>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="isValidValue">
          <div class="d-flex flex-wrap">
            <v-text-field
              :placeholder="$t('tickets.fields.contactId.placeholder')"
              :label="$t('tickets.fields.contactId.title')"
              v-model="formValue.contactId"
              append-icon="mdi-contacts-outline"
              class="font-weight-bold mr-3"
              autocomplete="off"
              :rules="rulesContactId"
            ></v-text-field>
            <v-select
              :items="channels"
              :placeholder="$t('tickets.fields.channel.placeholder')"
              :label="$t('tickets.fields.channel.title')"
              v-model="formValue.channel.type"
              class="font-weight-bold mr-2"
              append-icon="mdi-wan"
              autocomplete="off"
              :rules="rulesChannel"
            ></v-select>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    channels: Array,
    formsName: String,
    serviceName: String,
    form: Object,
    isValid: Boolean,
    isTicketSelected: Boolean,
  },
  data: () => ({}),
  methods: {
    newTicket() {
      this.$emit('new-ticket');
    },
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    reset() {
      return this.$refs.form.reset();
    }
  },
  computed: {
    formValue: {
      get: function () {
        return this.form;
      },
      set: function (value) {
        return this.$emit("update:form", value);
      },
    },
    isValidValue: {
      get: function () {
        return this.isValid;
      },
      set: function (value) {
        return this.$emit("update:isValid", value);
      },
    },
    rulesContactId() {
      return [
        (value) => {
          if (value == null || value === "")
            return this.$t("tickets.fields.contactId.required");
          if (value.length > 255)
            return this.$t("tickets.fields.contactId.maxLength");
          return true;
        },
      ];
    },
    rulesChannel() {
      return [
        (value) => {
          if (value == null || value === "")
            return this.$t("tickets.fields.channel.required");

          if (!this.$utils.matchChannelType(value) && !/^CALL$/.test(value))
            return this.$t("tickets.fields.channel.invalid");

          return true;
        },
      ];
    },
  },
};
</script>