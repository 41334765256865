<template>
  <div>
    <v-card
      class="rounded-lg"
      elevation="1"
      v-show="options.isVisible && isVisible"
    >
      <v-card-text class="px-5">
        <slot name="field-info"></slot>
        <v-text-field
          dense
          :placeholder="$t('forms.builder.fields.text.placeholder')"
          class="mb-4"
          v-model="modelValue"
          :rules="rulesField"
          :clearable="!options.isRequired"
          ref="field"
          :readonly="isReadonly"
        ></v-text-field>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { emailRegex } from "./../../../../constants";
import FieldDisplayMixin from "./../mixins/field-display";
export default {
  mixins: [FieldDisplayMixin],
  data: () => ({}),
  computed: {
    rulesField() {
      const rules = [];
      if (!this.options.isVisible || !this.isVisible) return rules;

      if (this.options.isRequired) {
        rules.push((value) => {
          if (value == null || value === "")
            return this.$t("common.fields.value.required");
          return true;
        });
      }

      for (const validator of this.options.validators) {
        if (validator.type === "text") {
          const minLength = validator.minLength;
          const maxLength = validator.maxLength;
          const message =
            validator.text ||
            this.$t("common.fields.value.char_between", {
              min: minLength,
              max: maxLength,
            });
          rules.push((value) => {
            if ((value == null || value === "") && !this.options.isRequired) return true;
            if (value == null || value === "") return message;
            if (value.length < minLength || value.length > maxLength)
              return message;
            return true;
          });
        }

        if (validator.type === "number") {
          const minValue = validator.minValue;
          const maxValue = validator.maxValue;
          const message =
            validator.text ||
            this.$t("common.fields.value.num_between", {
              min: minValue,
              max: maxValue,
            });
          rules.push((value) => {
            if ((value == null || value === "") && !this.options.isRequired) return true;
            if(isNaN(value)) return message;
            if (value < minValue || value > maxValue) return message;
            return true;
          });
        }

        if (validator.type === "email") {
          rules.push((value) => {
            if ((value == null || value === "") && !this.options.isRequired) return true;
            if (!emailRegex.test(value))
              return validator.text || this.$t("common.fields.value.not_email");
            return true;
          });
        }

        if (validator.type === "regex") {
          rules.push((value) => {
            if ((value == null || value === "") && !this.options.isRequired) return true;
            try {
              const regex = new RegExp(validator.regex);
              if (!regex.test(value))
                return validator.text || this.$t("common.fields.value.invalid");
            } catch (error) {
              console.log("invalid regex", validator.regex);
            }
            return true;
          });
        }
      }

      return rules;
    },
  },
};
</script>