<template>
  <div>
    <v-card
      class="rounded-lg"
      elevation="1"
      v-show="options.isVisible && isVisible"
    >
      <v-card-text class="px-5">
        <slot name="field-info"></slot>
        <template v-if="options.format === 'select'">
          <v-select
            dense
            :placeholder="$t('forms.builder.fields.select.placeholder')"
            class="mb-4"
            v-model="modelValue"
            :rules="rulesField"
            :clearable="!options.isRequired"
            :items="options.items"
            ref="field"
            return-object
            :multiple="options.multiple"
            :readonly="isReadonly"
          ></v-select>
        </template>
        <template v-else-if="options.format === 'checkbox'">
          <v-checkbox
            v-for="(item, k) in options.items"
            v-model="modelValue"
            :rules="rulesField"
            :value="item"
            :label="item.text"
            hide-details
            :key="k"
            :readonly="isReadonly"
          ></v-checkbox>
        </template>
        <template v-else-if="options.format === 'radio'">
          <v-radio-group v-model="modelValue">
            <v-radio
              v-for="(item, k) in options.items"
              :key="k"
              :label="item.text"
              :value="item"
              :readonly="isReadonly"
            ></v-radio>
          </v-radio-group>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FieldDisplayMixin from "./../mixins/field-display";

export default {
  mixins: [FieldDisplayMixin],
  created() {
    if (this.options.format === "checkbox") {
      this.initCheckbox();
    }
  },
  data: () => ({}),
  methods: {
    initCheckbox: function () {
      if (!this.options.multiple) return;
      this.modelValue = [];
    },
  },
  watch: {
    identifier() {
      const currentModelValue = { ...this.modelValue };
      if (this.options.format === "radio") {
        this.modelValue = this.options.items.find(
          (val) => val.value === currentModelValue.value
        );
      }
    },
  },
  computed: {
    rulesField() {
      const rules = [];
      if (!this.options.isVisible || !this.isVisible) return rules;

      if (this.options.isRequired) {
        rules.push((value) => {
          if (value == null || value === "")
            return this.$t("common.fields.value.required");
          return true;
        });
      }
      return rules;
    },
  },
};
</script>