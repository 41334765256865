<template>
  <v-app class="app">
    <app-bar
      :drawer="drawer"
      :options="{ 'clipped-left': true, 'clipped-right': true }"
    ></app-bar>
    <ticket-panel
      :tickets="tickets"
      :ticket.sync="ticket"
      :window-height="windowHeight"
      :drawer="drawer"
      @getMoreTickets="getMoreTickets"
      :schema="schema"
      :paginations="paginations.ticket"
      :channels="items.channels"
      :progression="progression"
      :forms-id="form.formsId"
      @apply-filter="applyFilter"
      :sla-items="slaItems"
      @refresh-tickets="refreshTickets"
    ></ticket-panel>
    <v-main class="pb-0 mb-0">
      <v-container fluid class="px-8 pt-5 pb-0">
        <div class="ticket">
          <v-card class="rounded-lg mb-4">
            <div class="d-flex align-center px-4 py-3 flex-wrap">
              <v-select
                v-model="form.serviceId"
                :placeholder="$i18n.t('tickets.fields.serviceId.placeholder')"
                class="pt-0 mr-3 font-weight-bold"
                hide-details
                :loading="loading.service"
                item-text="name"
                item-value="id"
                :items="items.services"
                @change="changeService"
                append-icon="mdi-domain"
              ></v-select>
              <v-select
                v-model="form.formsId"
                :placeholder="$i18n.t('tickets.fields.formsId.placeholder')"
                class="pt-0 mr-3 font-weight-bold"
                hide-details
                :loading="loading.forms"
                item-text="name"
                item-value="id"
                :items="items.forms"
                @change="changeForms"
                append-icon="mdi-form-select"
              ></v-select>
            </div>
          </v-card>
          <div v-if="!loading.section" class="forms">
            <ticket-header
              :is-ticket-selected="isTicketSelected"
              :form="form"
              :forms-name="formsName"
              :service-name="serviceName"
              :channels="items.channels"
              :is-valid.sync="isValidTicketHeader"
              @new-ticket="newTicket"
              ref="header"
            ></ticket-header>
            <div v-if="!form.serviceId || !form.formsId">
              <v-card class="py-0 mb-4 rounded-lg">
                <v-card-text
                  ><div
                    class="
                      d-flex
                      justify-center
                      align-center
                      fill-height
                      flex-column
                    "
                  >
                    <span class="text-subtitle-1 grey--text text--darken-2">
                      {{ $i18n.t("tickets.help.title") }}
                    </span>
                  </div></v-card-text
                >
              </v-card>
            </div>
            <div v-else>
              <v-card v-if="!forms" class="py-0 mb-4 rounded-lg">
                <div
                  class="
                    d-flex
                    justify-center
                    align-center
                    fill-height
                    flex-column
                  "
                >
                  <span class="mb-2">
                    <v-icon color="grey darken-2" x-large
                      >mdi-alert-outline</v-icon
                    >
                  </span>
                  <span class="text-h6 grey--text text--darken-2">{{
                    $i18n.t("tickets.errors.load")
                  }}</span>
                </div>
              </v-card>
              <div v-else class="mb-5">
                <forms-display
                  ref="forms"
                  :schema="schema"
                  :is-valid.sync="isValidTicketData"
                  :source="ticket.data"
                  :identifier="formsDisplayIdentifier"
                  :update="isTicketSelected"
                  :can-update="canUpdate"
                ></forms-display>
                <div class="mb-4" v-if="hasSla">
                  <ticket-sla-form
                    :sla-data="ticket.sla"
                    :sla="sla"
                    ref="sla"
                    :readonly="isTicketSelected"
                  ></ticket-sla-form>
                </div>
                <div v-if="hasProgress && !isTicketSelected" class="mb-4">
                  <v-card class="py-0 rounded-lg">
                    <v-card-text class="px-5">
                      <div class="font-weight-bold text-subtitle-1 mb-5 mt-2">
                        {{ progression.title }}
                      </div>
                      <ticket-progress-form
                        :progression-statuses="progression.states"
                        ref="progression"
                        :is-valid.sync="isValidProgression"
                      ></ticket-progress-form>
                    </v-card-text>
                  </v-card>
                </div>
                <div
                  class="d-flex justify-end"
                  v-if="!isTicketSelected || canUpdate"
                >
                  <v-btn
                    :loading="loading.save"
                    @click="save()"
                    color="primary"
                    :disabled="!isValidForSubmit"
                    class="mt-2"
                  >
                    <span v-if="isTicketSelected">{{ $t("btn.update") }}</span>
                    <span v-else>{{ $t("btn.save") }}</span>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="d-flex fill-height align-center justify-center">
            <v-progress-circular
              indeterminate
              color="secondary"
              width="4"
              size="60"
            ></v-progress-circular>
          </div>
        </div>
      </v-container>
    </v-main>

    <ticket-infos
      v-if="forms"
      :drawer="drawer"
      :ticket="ticket"
      :forms="forms"
      :window-height="windowHeight"
      :contact-info="contactInfo"
      :identifier.sync="form.contactId"
      @updateProgress="updateProgress"
      @update-contact-info="updateContactInfo"
    ></ticket-infos>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { paginationLimit } from "./../../constants";
import AppBar from "./../../components/layout/app-bar";
import TicketPanel from "./ticket-panel";
import TicketHeader from "./ticket-header";
import TicketInfos from "./ticket-infos";
import TicketProgressForm from "./ticket-progress-form.vue";
import TicketSlaForm from "./ticket-sla-form.vue";
import FormsDisplay from "./../forms/display/index";

export default {
  props: {
    query: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  mounted() {
    let self = this;
    this.windowHeight = window.innerHeight - 150;

    this.$nextTick(function() {
      window.addEventListener("resize", function() {
        self.windowHeight = window.innerHeight - 150;
      });
      window.addEventListener("resize", function() {
        self.windowWidth = window.innerWidth;
      });
    });
  },
  created() {
    this.setChannelTypeItems();
  },
  data: () => ({
    drawer: {
      left: true,
      right: true,
    },
    ticket: {},
    tickets: [],
    windowHeight: null,
    paginations: {
      ticket: {
        page: 0,
        limit: paginationLimit,
        state: null,
        infiniteId: +new Date(),
      },
    },
    items: {
      services: [],
      forms: [],
      channels: [],
    },
    forms: null,
    form: {
      contactId: null,
      channel: {
        type: null,
      },
      serviceId: null,
      formsId: null,
    },
    loading: {
      section: false,
      service: false,
      forms: false,
      save: false,
    },
    contactInfo: {},
    isValidTicketData: false,
    isValidTicketHeader: false,
    isValidProgression: false,
    formsDisplayIdentifier: null,
  }),
  methods: {
    ...mapActions({
      request: "request",
      notify: "notification/notify",
      setFilter: "ticket/setFilter",
    }),
    async save() {
      let isValid = true;
      this.notify({ status: false });

      if (this.isTicketSelected) {
        if (!this.$refs.forms.validate() || !this.$refs.header.validate()) {
          isValid = false;
        }
      } else {
        if (
          !this.$refs.forms.validate() ||
          !this.$refs.header.validate() ||
          !this.validateProgress()
        ) {
          isValid = false;
        }
      }

      if (!isValid) {
        return this.notify({ message: this.$t("form_error") });
      }

      this.loading.save = true;

      let url;
      let data = {
        formsId: this.form.formsId,
        contact: this.form.contactId,
        channel: this.form.channel.type,
        data: this.$refs.forms.getFormData(),
      };
      if (this.isTicketSelected) {
        url = "tickets.update";
        data.ticketId = this.ticket._id;
      } else {
        url = "tickets.create";
        // set sla
        if (this.hasSla) {
          const sla = this.$refs.sla.getSlaData();
          data.sla = sla.sla;
        }
        // set progress
        if (this.hasProgress) {
          const progression = this.$refs.progression.getProgressionData();
          if (progression) {
            data.status = {
              step: progression.status,
              comment: progression.comment,
            };
          }
        }
      }

      try {
        const response = await this.request({
          url: url,
          method: "POST",
          data: data,
          messages: {
            200: this.$t("tickets.edit.success"),
            201: this.$t("tickets.add.success"),
            403: true,
            400: true,
            404: this.$t("forms.errors.404"),
            422: (errors) => {
              if (errors.code === "E100") {
                return self.$t("form_error");
              } else if (errors.code == "E101") {
                return self.$t("error_occured");
              }
            },
            500: true,
          },
        });
        if (!this.isTicketSelected) {
          // reset all form
          this.resetForm();
          // add ticket in the list
          this.addTicket(response.data);
          this.$nextTick(() => {
            this.ticket = response.data;
          });
        } else {
          this.updateTicketById(this.ticket._id, response.data);
        }
      } catch (error) {
        // empty
      }
      this.loading.save = false;
    },
    async getTickets() {
      let query = `formsId=${this.form.formsId}&page=${this.paginations.ticket.page}&limit=${this.paginations.ticket.limit}`;

      query += this.$utils.getTicketFilterQuery(this.filters);

      try {
        const response = await this.request({
          url: `tickets.list?${query}`,
        });
        const data = response.data;
        const meta = data.meta;
        const items = data.items;
        if (meta.page === 1) {
          // select ticket
          if (this.filters.identifier) this.ticket = items[0] || {};
          this.tickets = items;
        } else {
          this.tickets.push(...items);
        }
        if (this.paginations.ticket.state) {
          if (
            this.paginations.ticket.page < Math.ceil(meta.total / meta.size)
          ) {
            this.$nextTick(() => {
              this.paginations.ticket.state.loaded();
            });
          } else {
            this.paginations.ticket.state.complete();
          }
        }
      } catch (error) {
        this.paginations.ticket.page -= 1;
        if (this.paginations.ticket.state) {
          this.paginations.ticket.state.error();
        }
      }
    },
    parseFilters(filters) {
      let query = "";
      for (const prop in filters) {
        let data = filters[prop];
        if (data == null || data === "") continue;
        if (typeof data === "object") {
          data = JSON.stringify(filters[prop]);
        }
        query += `&${prop}=` + data;
      }
      return query;
    },
    async setServiceItems() {
      this.items.services = [];
      this.loading.service = true;
      try {
        this.items.services = (
          await this.request({ url: "services.items" })
        ).data;
      } catch (error) {
        // empty
      }
      this.loading.service = false;
      return;
    },
    async setFormsItem(serviceId) {
      this.items.forms = [];
      if (!serviceId) return;
      this.loading.forms = true;
      try {
        this.items.forms = (
          await this.request({
            url: `forms.items?filter=service.id||$eq||${serviceId}`,
          })
        ).data;
      } catch (error) {
        // epmpty
      }
      this.loading.forms = false;
      return;
    },
    async setForms() {
      this.forms = null;
      if (!this.form.formsId || !this.form.serviceId) return;

      this.loading.section = true;

      try {
        const forms = (
          await this.request({
            url: `forms.get?formsId=${this.form.formsId}`,
            messages: { 403: true, 404: this.$i18n.t("forms.errors.404") },
          })
        ).data;
        this.forms = forms;
      } catch (error) {
        // empty
      }
      this.loading.section = false;
      this.$nextTick(() => {
        const ps = this.$refs["forms-wrapper"];
        if (ps) {
          ps.update();
        }
      });
      return;
    },
    async changeForms(val) {
      const query = Object.assign({}, this.$route.query || {}, { form: val });
      this.$router.push({ query: query }).catch(() => {});
      // reset pagination ticket
      this.resetPagination("ticket");
      // reset tickets list
      this.resetTicketList();
    },
    setChannelTypeItems() {
      this.items.channels = [...this.channelTypes];
      this.items.channels.push({ text: "CALL", value: "CALL" });
    },
    changeService(val) {
      const query = Object.assign({}, this.$route.query || {}, {
        service: val,
      });
      this.form.formIds = null;
      this.$router.push({ query: query }).catch(() => {});
      // reset pagination ticket
      this.resetPagination("ticket");
      // reset tickets list
      this.resetTicketList();
    },
    getMoreTickets($state) {
      this.paginations.ticket.page += 1;
      this.paginations.ticket.state = $state;
      // get tickets
      this.getTickets();
    },
    setFormsIdAndServiceId(formsId, serviceId) {
      const service = this.parseServiceId(serviceId);
      const form = this.parseFormsId(formsId);

      this.form.serviceId = service ? service : null;
      this.form.formsId = service ? form : null;
    },
    parseServiceId(serviceId) {
      if (!serviceId) return null;
      if (!this.items.services.length) {
        return null;
      } else {
        const find = this.items.services.find(
          (service) => service.id === parseInt(serviceId)
        );
        if (find) {
          return parseInt(serviceId);
        }
      }
      return null;
    },
    parseFormsId(formsId) {
      if (!formsId) return null;
      if (!this.items.forms.length) {
        return null;
      } else {
        const find = this.items.forms.find(
          (form) => form.id === parseInt(formsId)
        );
        if (find) {
          return parseInt(formsId);
        }
      }
      return null;
    },
    newTicket() {
      this.ticket = {};
      this.formDisplayIdentifier = null;
      this.resetForm();
    },
    resetTicketList() {
      this.tickets = [];
    },
    addTicket(data) {
      this.tickets.unshift({ ...data });
    },
    updateContactInfo(contactInfo) {
      this.$set(this, "contactInfo", contactInfo);
    },
    updateProgress(progress) {
      this.$set(this.ticket, "progress", progress);
      /** update progress tickets list */
      const ticketIndex = this.tickets.findIndex(
        (t) => this.ticket?._id === t._id
      );
      if (ticketIndex > -1) {
        this.$set(this.tickets[ticketIndex], "progress", progress);
      }
    },
    resetForm() {
      this.$nextTick(() => {
        // reset validation
        this.$refs?.forms?.resetValidation();
        this.$refs?.progression?.resetValidation();
        this.$refs?.header?.resetValidation();

        // reset form
        this.$refs?.forms?.reset();
        this.$refs?.header?.reset();
        this.$refs?.progression?.reset();
      });
    },
    resetPagination(item) {
      this.$set(this.paginations, item, {
        page: 0,
        limit: paginationLimit,
        state: null,
        infiniteId: +new Date(),
      });
    },
    updateTicketById(ticketId, data) {
      const findIndex = this.tickets.findIndex(
        (ticket) => ticket._id === ticketId
      );
      if (findIndex > -1) {
        this.$set(this.tickets, findIndex, { ...data });
      }
    },
    applyFilter() {
      this.resetPagination("ticket");
      this.resetTicketList();
    },
    refreshTickets() {
      this.resetPagination("ticket");
      this.resetTicketList();
    },
    validateProgress() {
      if (!this.hasProgress) {
        return true;
      }
      return this.$refs.progression.validate();
    },
  },
  watch: {
    query: {
      async handler(query) {
        const { to, from } = query;
        const { contact, channel, service, identifier, form } = to;
        if (contact) {
          this.setFilter({ item: "contact", value: contact });
          this.form.contactId = contact;
        }

        if (channel) {
          this.form.channel.type = to.channel;
        }

        if (identifier) {
          this.setFilter({ item: "identifier", value: identifier });
        }

        this.loading.section = true;

        // load service items
        if (!this.items.services.length) {
          await this.setServiceItems();
        }
        // load form items
        if (service !== from.service) {
          await this.setFormsItem(service);
        }

        // set formId and serviceId on form data
        this.setFormsIdAndServiceId(form, service);

        // load forms
        await this.setForms();
        this.loading.section = false;
      },
      immediate: true,
      deep: true,
    },
    "$vuetify.breakpoint.smAndDown"(val) {
      if (!val) {
        this.drawer.left = true;
      }
    },
    "ticket._id"(val) {
      if (!val) return;
      this.form.contactId = this.ticket?.contact;
      this.form.channel.type = this.ticket?.channel?.type;

      this.formsDisplayIdentifier = +new Date();
    },
  },
  computed: {
    ...mapGetters({
      channelTypes: "channel/channelTypes",
      filters: "ticket/filters",
    }),
    serviceName() {
      if (!this.form.serviceId || !this.items.services.length) return null;
      const find = this.items.services.find(
        (service) => service.id === this.form.serviceId
      );
      if (find) return find.name;
      return null;
    },
    formsName() {
      if (!this.form.formsId || !this.items.forms.length) return null;
      const find = this.items.forms.find(
        (form) => form.id === this.form.formsId
      );
      if (find) return find.name;
      return null;
    },
    isTicketSelected() {
      return this.ticket._id ? true : false;
    },
    schema() {
      return this.forms?.data?.schema || [];
    },
    slaItems() {
      const items = [];
      const targets = this.forms?.data?.sla?.targets || [];
      for (const target of targets) {
        items.push({ text: target.title, value: target.value });
      }
      return items;
    },
    progression() {
      return this.forms?.data?.progress || {};
    },
    sla() {
      return this.forms?.data?.sla || {};
    },
    hasProgress() {
      return this.progression?._id ? true : false;
    },
    hasSla() {
      return this.sla?._id ? true : false;
    },
    isValidForSubmit() {
      if (this.isTicketSelected || !this.hasProgress) {
        return this.isValidTicketData && this.isValidTicketHeader;
      } else {
        return (
          this.isValidTicketData &&
          this.isValidTicketHeader &&
          this.isValidProgression
        );
      }
    },
    canUpdate() {
      if (!this.isTicketSelected) return false;
      if (this.$permission.$can("ticket.update.all", ["user", "service"])) {
        return true;
      } else if (
        this.$permission.$can("ticket.update", ["user", "service"]) &&
        this.ticket.author === this.$auth.user().username
      ) {
        return true;
      }
      return false;
    },
  },
  components: {
    AppBar,
    TicketPanel,
    TicketHeader,
    TicketInfos,
    FormsDisplay,
    TicketProgressForm,
    TicketSlaForm,
  },
};
</script>
