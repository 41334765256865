<template>
  <div>
    <v-text-field v-model="modelValue" dense clearable :label="options.title"></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    options: Object,
    value: [String, Object, Array]
  },
  data: () => ({}),
  computed: {
    modelValue: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        return this.$emit('update:value', value);
      }
    }
  }
}
</script>