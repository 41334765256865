<template>
  <div>
    <v-list nav class="pl-0 pr-0">
      <v-list-item
        :input-value="ticket && t._id === ticket._id"
        @click="selectTicket(t)"
        v-for="t in tickets"
        :key="t.id"
        ripple
        selectable
      >
        <conversation-list-avatar
          :type="t.channel.type"
        ></conversation-list-avatar>
        <v-list-item-content class="px-2">
          <v-list-item-title class="font-weight-bold">{{
            t.contact
          }}</v-list-item-title>
          <v-list-item-subtitle>
            <span class="grey--text text-body-2 text--darken-2">
              {{ $t("tickets.fields.author.title") }}
              <span class="text--darken-3">{{ t.author }}</span>
            </span>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="t.identifier">
            <span class="grey--text text-body-2 text--darken-2">
              <span class="text--darken-3 font-weight-bold"
                >#{{ t.identifier }}</span
              >
            </span>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <span class="grey--text text-body-2 text--darken-1">{{
              $moment(t.created).format("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </v-list-item-subtitle>
          <template v-if="t.progress && t.progress.length">
            <v-list-item-subtitle
              class="d-flex align-center justify-space-between"
            >
              <span :title="t.progress[t.progress.length - 1].state" class="text-body-2 text-truncate font-weight-bold" :style="{'color': t.progress[t.progress.length - 1].color
                    ? t.progress[t.progress.length - 1].color
                    : 'grey lighten-1'}">{{
                t.progress[t.progress.length - 1].state
              }}</span>
                <v-icon :color="
                  t.progress[t.progress.length - 1].color
                    ? t.progress[t.progress.length - 1].color
                    : 'grey lighten-1'
                ">mdi-radiobox-marked</v-icon>
            </v-list-item-subtitle>
          </template>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import ConversationListAvatar from "./../chat/conversation-list-avatar";
export default {
  props: {
    tickets: {
      type: Array,
      default: function() {
        return [];
      },
    },
    ticket: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data: () => ({}),
  methods: {
    selectTicket(ticket) {
      this.ticketValue = ticket;
    },
  },
  computed: {
    ticketValue: {
      get: function() {
        return this.ticket;
      },
      set: function(val) {
        return this.$emit("update:ticket", val);
      },
    },
  },
  components: {
    ConversationListAvatar,
  },
};
</script>
