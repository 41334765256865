<template>
  <component
    :is="`${options.type}-field`"
    :options="options"
    :field-items="fieldItems"
    :value.sync="modelValue"
    :form-data="formData"
    :identifier="identifier"
    :update="update"
    :can-update="canUpdate"
  >
    <template v-slot:field-info>
      <slot name="field-info"></slot>
    </template>
  </component>
</template>

<script>
import TextField from "./fields/text";
import ModelField from "./fields/model";
import TextareaField from "./fields/textarea";
import SelectField from "./fields/select";
import DatetimeField from "./fields/datetime";

export default {
  props: {
    update: Boolean,
    identifier: [String, Number],
    canUpdate: Boolean,
    value: {
      type: [String, Object, Array],
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    fieldItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
    formData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({}),
  computed: {
    modelValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        return this.$emit("update:value", value);
      },
    },
  },
  components: {
    TextField,
    ModelField,
    TextareaField,
    SelectField,
    DatetimeField,
  },
};
</script>