<template>
  <div>
    <v-form ref="form" class="forms" v-model="isValidValue">
      <div v-for="(field, index) in schema" :key="index">
        <field
          class="mb-3"
          :options="field"
          :value.sync="formData[field.name]"
          :form-data="formData"
          :identifier="identifier"
          :update="update"
          :can-update="canUpdate"
          ><template v-slot:field-info
            ><div class="font-weight-bold text-subtitle-1 mb-3 mt-2">
              {{ field.title }}
            </div>
            <div class="mb-4 text-justify" v-if="field.placeholder">
              {{ field.placeholder }}
            </div></template
          ></field
        >
      </div>
    </v-form>
  </div>
</template>

<script>
import Field from "./field.vue";
export default {
  created() {
    this.initFormData();
  },
  props: {
    identifier: [String, Number],
    isValid: {
      type: Boolean,
      default: true,
    },
    schema: {
      type: Array,
      default: function () {
        return [];
      },
    },
    source: Object,
    update: Boolean,
    canUpdate: Boolean
  },
  data: () => ({
    formData: {},
  }),
  methods: {
    initFormData() {
      for (const field of this.schema) {
        const value =
          this.source && this.source[field.name]
            ? this.source[field.name]
            : null;
        this.$set(this.formData, field.name, value);
      }
    },
    getFormData() {
      return this.formData;
    },
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    reset() {
      return this.$refs.form.reset();
    }
  },
  watch: {
    source(val) {
      if (!val) return;
      this.initFormData();
    },
  },
  computed: {
    isValidValue: {
      get() {
        return this.isValid;
      },
      set(value) {
        return this.$emit("update:isValid", value);
      },
    },
  },
  components: {
    Field,
  },
};
</script>