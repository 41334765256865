<template>
  <div>
    <v-card
      class="rounded-lg"
      elevation="1"
      v-show="options.isVisible && isVisible"
    >
      <v-card-text class="px-5">
        <slot name="field-info"></slot>
        <v-textarea
          dense
          :placeholder="$t('forms.builder.fields.text.placeholder')"
          class="mb-4"
          v-model="modelValue"
          :rules="rulesField"
          :clearable="!options.isRequired"
          no-resize
          ref="field"
          :rows="2"
          :readonly="isReadonly"
        ></v-textarea>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FieldDisplayMixin from "./../mixins/field-display";
export default {
  mixins: [FieldDisplayMixin],
  data: () => ({
  }),
  computed: {
    rulesField() {
      const rules = [];
      if (!this.options.isVisible || !this.isVisible) return rules;

      if (this.options.isRequired) {
        rules.push((value) => {
          if (value == null || value === "")
            return this.$t("common.fields.value.required");
          return true;
        });

        for (const validator of this.options.validators) {
          if (validator.type === "text") {
            const minLength = validator.minLength;
            const maxLength = validator.maxLength;
            const message =
              validator.text ||
              this.$t("common.fields.value.char_between", {
                min: minLength,
                max: maxLength,
              });
            rules.push((value) => {
              if (value == null || value === "") return true;
              if (value.length < minLength || value.length > maxLength)
                return message;
              return true;
            });
          }

          if (validator.type === "regex") {
            rules.push((value) => {
              if (value == null || value === "") return true;
              try {
                const regex = new RegExp(validator.regex);
                if (!regex.test(value))
                  return (
                    validator.text || this.$t("common.fields.value.invalid")
                  );
              } catch (error) {
                console.log("invalid regex", validator.regex);
              }
              return true;
            });
          }
        }
      }
      return rules;
    },
  },
};
</script>