<template>
  <v-navigation-drawer
    v-show="drawer.right"
    width="350"
    hide-overlay
    clipped
    right
    app
    v-model="drawer.right"
    class="elevation-1 app-nav"
  >
    <v-list-item class="mt-1">
      <v-list-item-content>
        <v-list-item-title class="title">
          <span>{{ $i18n.t("tickets.infos.title") }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
          <span>{{ $i18n.t("tickets.infos.subtitle") }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-tabs v-model="tab" background-color="grey lighten-5" fixed-tabs>
      <v-tabs-slider></v-tabs-slider>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-tab href="#contact" v-on="on" v-bind="attrs">
            <v-icon>mdi-card-account-details-outline</v-icon>
          </v-tab>
        </template>
        <span>{{ $t("tickets.infos.tabs.contact.title") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            :disabled="!isTicketSelected || !hasProgress"
            href="#progress"
            v-on="on"
            v-bind="attrs"
          >
            <v-icon>mdi-chevron-triple-right</v-icon>
          </v-tab>
        </template>
        <span>{{ $t("tickets.infos.tabs.progress.title") }}</span>
      </v-tooltip>
    </v-tabs>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      :style="{ height: windowHeight - 30 + 'px' }"
      ref="infos-wrapper"
    >
      <v-tabs-items v-model="tab">
        <v-tab-item v-if="isTicketSelected && hasProgress" value="progress">
          <v-card flat>
            <v-card-text>
              <ticket-progress
                :progression-data="ticket.progress"
                :progression="forms.data.progress"
                :forms-id="forms.id"
                :ticket-id="ticket._id"
                @updateProgress="updateProgress"
              ></ticket-progress>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="contact">
          <v-card flat>
            <v-card-text v-if="!loading.getContact">
              <contact-info-form
                :contact-info="contactInfo"
                :identifier.sync="identifier"
                @update:identifier="updateIdentifier"
                :service-id="forms.service.id"
                @updateContactInfo="updateContactInfo"
                :reload-id="reloadContactInfoId"
              ></contact-info-form>
            </v-card-text>
            <v-card-text v-else>
              <div class="text-center mt-5">
                <v-progress-circular
                  indeterminate
                  color="secondary"
                  width="4"
                  size="40"
                ></v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>


<script>
import TicketProgress from "./../tickets/ticket-progress";
import ContactInfoForm from "./../chat/contact-info";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  props: {
    drawer: {
      type: Object,
      default: function () {
        return {
          right: true,
        };
      },
    },
    contactInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    windowHeight: {
      type: Number,
    },
    ticket: {
      type: Object,
      default: function () {
        return {};
      },
    },
    forms: {
      type: Object,
      default: function () {
        return {};
      },
    },
    identifier: {
      type: String,
    },
  },
  data: () => ({
    tab: null,
    loading: {
      getContact: false,
    },
    isTicketSelected: false,
    reloadContactInfoId: +new Date(),
  }),
  methods: {
    updateIdentifier(identifier) {
      this.$emit("update:identifier", identifier);
    },
    updateContactInfo(contactInfo) {
      this.$emit("update-contact-info", contactInfo);
    },
    updateProgress(progress) {
      this.$emit("updateProgress", progress);
    },
  },
  watch: {
    "ticket._id": {
      handler(val) {
        if (!val) {
          this.isTicketSelected = false;
          this.tab = "contact";
        } else {
          this.isTicketSelected = true;
        }
        // update contact info
        this.updateIdentifier(this.ticket?.contact);
        // reload contact info
        this.reloadContactInfoId = +new Date();
      },
    },
  },
  computed: {
    hasProgress() {
      return this.$utils.getPropertyValue(this.forms, "data.progress")
        ? true
        : false;
    },
  },
  components: {
    PerfectScrollbar,
    ContactInfoForm,
    TicketProgress,
  },
};
</script>