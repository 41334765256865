<template>
  <v-navigation-drawer
    width="320"
    v-model="drawer.left"
    hide-overlay
    app
    class="elevation-1 app-nav"
    clipped
  >
    <v-list-item class="mt-1 px-2">
      <v-list-item-action class="ml-0 mr-2">
        <v-btn icon @click="refreshTickets()">
          <v-icon color="grey lighten-1">mdi-refresh</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-content class="ml-0">
        <v-list-item-title class="title">{{ $i18n.t("tickets.list.title") }}</v-list-item-title>
        <v-list-item-subtitle>{{ $i18n.t("tickets.list.subtitle") }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon @click="displayFilter()">
          <v-icon color="grey lighten-1">mdi-filter-variant</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>
    <div :style="{ height: windowHeight - 50 + 'px' }">
      <template v-if="formsId">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          :style="{ height: windowHeight - 10 + 'px' }"
          ref="tickets-wrapper"
          class="infinite-ticket-wrapper"
        >
          <ticket-list :tickets="tickets" :ticket.sync="ticketValue"></ticket-list>
          <infinite-loading
            force-use-infinite-wrapper=".infinite-ticket-wrapper"
            :distance="10"
            @infinite="getMoreTickets"
            :identifier="paginations.infiniteId"
            direction="bottom"
          >
            <v-progress-circular
              indeterminate
              color="secondary"
              width="3"
              size="40"
              slot="spinner"
              class="mt-4"
            ></v-progress-circular>
            <span slot="no-more"></span>
            <span slot="no-results"></span>
            <div slot="error" class="text-body-1" slot-scope="{ trigger }">
              <div>{{ $i18n.t("error_occured") }}</div>
              <div>
                <v-btn small depressed @click="trigger">{{ $i18n.t("btn.retry") }}</v-btn>
              </div>
            </div>
          </infinite-loading>
        </perfect-scrollbar>
      </template>
      <template v-else></template>
    </div>
     <!--ticket filter -->
    <ticket-filter
      :schema="schema"
      :dialog.sync="dialog.filter"
      :statuses="progression.states"
      :channels="channels"
      @apply-filter="applyFilter"
      :slaItems="slaItems"
    ></ticket-filter>
  </v-navigation-drawer>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import InfiniteLoading from "vue-infinite-loading";
import TicketList from "./ticket-list";
import TicketFilter from "./ticket-filter";
export default {
  props: {
    formsId: {
      type: [String, Number]
    },
    drawer: {
      type: Object,
      default: function() {
        return {
          left: true
        };
      }
    },
    windowHeight: {
      type: Number
    },
    tickets: {
      type: Array,
      default: function() {
        return [];
      }
    },
    ticket: {
      type: Object,
      default: function() {
        return {};
      }
    },
    paginations: {
      type: Object,
      default: function() {
        return {};
      }
    },
    schema: {
      type: Array,
      default: function() {
        return [];
      }
    },
    progression: {
      type: Object,
      default: function() {
        return {}
      }
    },
    channels: {
      type: Array,
      default: function() {
        return [];
      }
    },
    slaItems: Array,
  },
  data: () => ({
    dialog: {
      filter: false
    }
  }),
  methods: {
    getMoreTickets($state) {
      this.$emit("getMoreTickets", $state);
    },
    refreshTickets() {
      this.$emit('refresh-tickets');
    },
    displayFilter() {
      this.dialog.filter = true;
    },
    applyFilter() {
      this.$emit('apply-filter')
    },
  },
  computed: {
    ticketValue: {
      get: function() {
        return this.ticket;
      },
      set: function(val) {
        return this.$emit('update:ticket', val)
      }
    },
    statuses() {
      return this.progression?.states || []
    }
  },
  components: {
    TicketList,
    InfiniteLoading,
    PerfectScrollbar,
    TicketFilter
  }
};
</script>