<template>
  <div>
    <v-autocomplete
      v-model="modelValue"
      dense
      clearable
      :label="options.title"
      cache-items
      :items="items"
      :loading="loading"
      :search-input.sync="search"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    options: Object,
    value: [String, Object, Array],
  },
  data: () => ({
    items: [],
    search: null,
    loading: false,
    lastQuery: null,
  }),
  methods: {
    ...mapActions({ request: "request" }),
    async query(v) {
      this.loading = true;
      const params = { identifier: this.options.hash };
      const items = [];

      if (v) {
        items.push({ label: v });
      }
      try {
        const response = await this.request({
          url: "models-fields.get",
          params: { ...params, item: JSON.stringify(items) },
        });
        this.items = this.$utils
          .getPropertyValue(response.data, "data.items", [])
          .map((v) => {
            return {
              text: v.item.label,
              value: v.item.value,
            };
          });
      } catch (error) {
        // empty
        console.log(error);
      }
      this.loading = false;
    },
  },
  watch: {
    search(val) {
      if (val == null || val === "") return;

      // cancel old query
      if (this.lastQuery) clearTimeout(this.lastQuery);

      this.lastQuery = setTimeout(() => {
        this.query(val);
      }, 500);
    },
  },
  computed: {
    modelValue: {
      get: function () {
        return this.value.value;
      },
      set: function (value) {
        return this.$emit("update:value", { value: value });
      },
    },
  },
};
</script>