export default {
  props: {
    identifier: [String, Number],
    update: Boolean,
    canUpdate: Boolean,
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    value: {
      type: [String, Object, Array],
    },
    formData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    isMounted: false,
    focused: false,
  }),
  mounted() {
    this.isMounted = true;
  },
  methods: {
    onFocus: function () {
      setTimeout(() => {
        this.focused = true;
      }, 1000);
    },
    getTargetConditionValue(conditionField) {
      return this.formData[conditionField]?.value || this.formData[conditionField];
    }
  },
  computed: {
    modelValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        return this.$emit("update:value", value);
      },
    },
    isVisible: function () {
      if (!this.options.visibleIf) return true;
      if (!this.options.visibleIf.length) return true;

      const isVisible = false;

      let expression = "";
      let i = 0;

      try {
        for (const condition of this.options.visibleIf) {
          if (!this.$utils.hasProperty(this.formData, condition.field)) {
            continue;
          }

          const value = this.getTargetConditionValue(condition.field);

          if (
            value == null ||
            value === ""
          ) {
            continue;
          }


          if (this.options.visibleIf[i - 1]) {
            expression +=
              this.options.visibleIf[i - 1].logic == "OR" ? " || " : " && ";
          }

          const condValue = condition.value;
          switch (condition.operator.toLowerCase()) {
            case "$eq":
              expression += `'${value ? value.toLowerCase() : value}' == '${condValue ? condValue.toLowerCase() : condValue
                }'`;
              break;
            case "$lte":
              expression += `'${value}' <= '${condition.value}'`;
              break;
            case "$gte":
              expression += `'${value}' >= '${condition.value}'`;
              break;
            case "$gt":
              expression += `'${value}' > '${condition.value}'`;
              break;
            case "$lt":
              expression += `'${value}' < ${condition.value}`;
              break;
            case "$contains":
              expression += `${(value ? value.toLowerCase() : value).includes(
                `${condition.value}`
              )}`;
              break;
            case "$ne":
              expression += `'${value}' != '${condition.value}'`;
              break;
            case "$ncontains":
              expression += `!${(value ? value.toLowerCase() : value).includes(
                `${condition.value}`
              )}`;
              break;
          }
          i++;
        }
        if (expression) {
          return new Function(`return ${expression}`)();
        }
      } catch (error) {
        console.log("wrong evaluation visibility of field", this.options.name);
      }

      return isVisible;
    },
    isReadonly() {
      return this.options.readOnly || (this.update && !this.canUpdate)
    },
    hasError() {
      if (!this.isMounted) return;
      if (!this.focused) return;
      return this.$refs?.field?.hasError;
    },
  },
};
