<template>
  <div>
    <v-text-field dense v-model="modelValue" :label="options.title" clearable></v-text-field>
  </div>
</template>

<script>
import FieldFilterMixin from './../mixins/field-filter';
export default {
  mixins: [FieldFilterMixin],
  props: {
    options: Object,
    value: [String, Object, Array]
  },
  data: () => ({}),
}
</script>