<template>
  <v-dialog v-model="dialogValue" max-width="320">
    <v-card class="rounded-none">
      <v-tabs
        fixed-tabs
        v-model="tabs"
        style="border-radius: 0"
        v-if="format === 'datetime'"
      >
        <v-tab href="#date"><v-icon>mdi-calendar</v-icon></v-tab>
        <v-tab href="#time" :disabled="!date"
          ><v-icon>mdi-clock-outline</v-icon></v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item value="date">
          <v-date-picker
            v-model="date"
            full-width
            color="primary"
            :locale="locale"
            elevation="1"
            class="rounded-none"
            @click:date="changeDate"
          ></v-date-picker>
        </v-tab-item>
        <v-tab-item value="time" class="rounded-none">
          <v-time-picker
            format="24hr"
            v-model="time"
            full-width
            color="primary"
          ></v-time-picker>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel()">{{ $t("btn.clear") }}</v-btn>
        <v-btn text color="primary" @click="apply()" :disabled="!isValid"
          ><span class="font-weight-bold">{{ $t("btn.ok") }}</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  created() {
    this.initTab();
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: "datetime",
    },
  },
  data: () => ({
    tabs: "date",
    date: null,
    time: null,
  }),
  methods: {
    initTab() {
      if (this.format === "date") {
        this.tabs = "date";
      } else if (this.format === "time") {
        this.tabs = "time";
      }
    },
    apply() {
      let date;
      if (this.format === "date") {
        if (!this.date) return;
        date = this.date;
      } else if (this.format === "time") {
        if (!this.time) return;
        date = this.time;
      } else {
        if (!this.date || !this.time) return;
        date = `${this.date} ${this.time}:00`;
      }
      this.$emit("apply", date);

      this.dialogValue = false;
    },
    cancel() {
      this.$emit("apply", null);
      this.dialogValue = false;
    },
    changeDate() {
      if (this.format != "datetime") return;
      this.tabs = "time";
    },
  },
  watch: {
    dialogValue(val) {
      if (!val) return;
      if (this.format === "datetime") return;
      this.initTab();
    },
  },
  computed: {
    dialogValue: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        return this.$emit("update:dialog", value);
      },
    },
    locale() {
      return this.$utils.setLocaleBCP47(this.$i18n.locale);
    },
    isValid() {
      if (this.format === "date") {
        return this.date ? true : false;
      } else if (this.format === "time") {
        return this.time ? true : false;
      }
      return this.date && this.time;
    },
  },
};
</script>